export const getWebUrl = (url) => {
  return url.replace(/(?:https?:\/\/)?telegra\.ph/, 'https://src.channelquiz.io')
}

export const getMediaType = (url) => {
  //todo refactor, нужен другой способ определять тип медиа, возможно, придется резолвить ссылку
  if (url.toLowerCase().endsWith('.mp4')) {
    return 'video'
  }
  if (url.toLowerCase().endsWith('.gif')) {
    return 'animation'
  }
  return 'image'
}
